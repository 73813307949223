import React, { useContext, Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTable, setPixel, setLoading, setCurrentPage, setPagValues, deletePixel as deletePixelRedux, updatePixel, updateForm } from 'redux/features/pixelProcessor/pixelProcessorSlice';
import { Card, NewPaginate, ViewContext, Animate, useNavigate, Icon, Message, AuthContext } from 'components/lib';
import { Table } from '../../gsd-components/table/table';
import { addTypeCheck } from 'helpers/checkPermissionType';
import { checkLocation } from 'helpers/checklocation';
import Quota from 'helpers/quota';
import Formatation from 'helpers/format';
import webhook from './webhook.json';
import axios from 'axios';
import { useFacebook } from 'gsd-components/facebook/hook';
import uuid4 from 'uuid4';
export function Pixel() {
  const pixels = useSelector((state) => state.pixelProcessor.pixelProcessorList);
  const currentPage = useSelector((state) => state.pixelProcessor.currentPage);
  const pagValues = useSelector((state) => state.pixelProcessor.pagValues);
  const loading = useSelector((state) => state.pixelProcessor.loading);

  const webhookData = JSON.stringify(webhook, null, '\t');
  const context = useContext(ViewContext);
  const baseUrlPixel = checkLocation(1);
  const baseUrlMain = checkLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const format = new Formatation();
  const [quota, setQuota] = useState(null);
  const [resolutions, setResolutions] = useState(null);
  const accountId = authContext.user.account_id;
  const [isValidatingPixel, setIsValidatingPixel] = useState(false);
  const {
    isSyncSuccess,
    FacebookSuccessModal,
    showSyncModal, 
    setShowSyncModal,
    SyncAudienceModal,
    facebookIntegrationAccess,
    hasLoggedCondition,
    FacebookLoginModal,
    setFacebookAudienceData
    
  } = useFacebook();

  const navigatePixel = (data) => {
    navigate(`/pixel/${data.id}`);
    dispatch(setTable([]));
  };

  const addModal = () => {
    // Set up the initial modal with basic fields
    context.modal.show({
      title: 'Add Pixel',
      form: {
        id: {
          type: 'hidden',
          value: uuid4(),
        },
        pixel_trial: {
          label: 'Pixel Trial?',
          type: 'switch',
        }
      },
      buttonText: 'Next',
      url: `/api/pixel/`,
      method: 'GET',
    }, (res) => {
      if (res.pixel_trial.value) {
        dispatch(updateForm({
          fieldName: 'pixel_trial',
          value: res.pixel_trial.value,
        }));
        setTimeout(() => {
          showAdditionalFieldsModal();
        }, 500);
      } else {
        navigate('/pixel/wizard');
      }
    }
    );
  };
  
  const showAdditionalFieldsModal = () => {
    context.modal.show({
      title: 'Configure Trial Details',
      form: {
        trial_days: {
          label: 'Select the number of days for the trial',
          type: 'number',
          min: 1,
        },
        trial_resolutions: {
          label: `Select the number of resolutions up to ${resolutions}`,
          type: 'number',
          min: 1,
          max: resolutions,
        }
      },
      buttonText: 'Next',
      url: `/api/pixel/`,
      method: 'GET',
     }, (res) => {
        const fieldsToUpdate = ['trial_days', 'trial_resolutions'];
        fieldsToUpdate.forEach(field => {
          dispatch(updateForm({
            fieldName: field,
            value: res[field].value,
          }));
        });
        navigate('/pixel/wizard');
      }
    );
  };

  const addPixel = async () => {
    try {
      const quota = await axios.get(`${baseUrlMain}/api/access/quota/pixels`);
      const permit = quota.data.permit;
      if (permit) {
        addModal();
      } else {
        context.notification.show('Please contact support for Pixel credits', 'error', true);
      }
    } catch (err) {
      console.error(err);
      context.notification.show(`${err.response.data}`, 'error', true);
    }
  };

  const copyCode = async (data) => {
    const response = await axios.get(`${baseUrlPixel}/api/pixel/${data.id}/`);
    const getTag = response.data.data.docs?.filter((item) => item.id === data.id)[0]?.tag;
    navigator.clipboard.writeText(getTag);
    context.notification.show('copied successfully', 'success', true);
  };
  
  const editWebhook = (data) => {
    const trialDays = data.trial?.totalNumOfDays || 0;
    const trialResolutions = data.trial?.totalResolutions || 0;
    const buildForm = () => ({
      id: { type: 'hidden', value: data.id },
      data_webhook: {
        label: 'Webhook',
        type: 'text',
        value: data.data_webhook,
        errorMessage: 'Please enter a Webhook',
      },
      ...(data.trial?.isTrial && {
        isFinished: {
          type: 'hidden',
          value: data.trial.isFinished,
        },
        pixel_trial: {
          type: 'hidden',
          value: data.trial.isTrial,
        },
        trial_days: {
          label: 'Select the number of days for the trial',
          type: 'number',
          min: 0,
          value: trialDays,
        },
        trial_resolutions: {
          label: `Select the number of resolutions up to ${resolutions}`,
          type: 'number',
          min: 0,
          max: resolutions,
          value: trialResolutions,
        },
        status: {
          type: 'radio',
          label: 'Do you want to activate or end the trial?',
          options: [
            { value: 'activate', label: 'Activate Pixel' },
            { value: 'end', label: 'End Trial' },
          ],
        },
      }),
    });
  
    const handleResponse = async (res) => {
      const type = res.trial_days?.value > 0 && res.trial_resolutions?.value > 0
        ? 'both'
        : res.trial_days?.value > 0
        ? 'days'
        : res.trial_resolutions?.value > 0
        ? 'resolutions'
        : null;
  
      const isTrial = res.pixel_trial?.value || false;
      let endpoint = '';
  
      if (res.status) {
        const status = res.status.value;
        if (status === 'end') {
          endpoint = 'end-trial';
        } else if (status === 'activate') {
          endpoint = 'move-trial-to-active';
        }
      }
  
      try {
        const patchData = {
          id: data.id,
          data_webhook: res.data_webhook.value,
          ...(isTrial && {
            trial: {
              isTrial: res.pixel_trial.value,
              numOfDays: parseInt(res.trial_days?.value, 10) || 0,
              resolutions: parseInt(res.trial_resolutions?.value, 10) || 0,
              type,
            },
          }),
        };
  
        await axios.patch(`${baseUrlPixel}/api/pixel/${endpoint ? `${endpoint}/${data.id}` : data.id}`, patchData);
        context.notification.show(endpoint === 'end-trial' ? 'Pixel disabled' : 'Pixel updated', 'success', true);
  
        if (endpoint === 'end-trial') {
          dispatch(deletePixelRedux(data.id));
        } else if (endpoint === 'move-trial-to-active') {
          dispatch(updatePixel({
            id: data.id,
            updates: {
              trial: null,
            },
          }));
        } else {
          dispatch(updatePixel({
            id: data.id,
            updates: {
              data_webhook: res.data_webhook.value,
              'trial.totalNumOfDays': parseInt(res.trial_days?.value, 10) || 0,
              'trial.totalResolutions': parseInt(res.trial_resolutions?.value, 10) || 0,
            },
          }));
        }
      } catch (err) {
        console.error(err);
        context.notification.show('Failed to update pixel', 'error', true);
      }
    };
  
    context.modal.show({
      title: data.trial?.isTrial ? 'Update Webhook & Pixel Trial' : 'Update Webhook',
      text: data.trial?.isTrial ? '*(Pixel Trial you can have a day limit or a resolution limit or both.)' : null,
      form: buildForm(),
      buttonText: 'Save',
      url: `/api/pixel/`,
      method: 'GET',
    }, handleResponse);
  };
  
  const deletePixel = (data) => {
    context.modal.show({
      title: 'Delete Pixel',
      form: {},
      buttonText: 'Delete',
      text: `Are you sure you want to delete ${data.website_name}?`,
      url: `/api/pixel/${data.id}/archive`,
      method: 'PATCH',

    }, () => {
      context.notification.show('Pixel deleted successfully', 'success', true);
      dispatch(deletePixelRedux(data.id));
    });
  };

  const downloadCSV = (e, name) => {
    if (e.file_list) {
      const CSV = 'https://audiencelab-builder.s3.amazonaws.com/' + name;
      window.open(CSV, '_blank').focus();
    }
    return null;
  };

  const csvShowModal = (e) => {
    if (!e.file_list || e.file_list === null) {
      context.notification.show('No CSV files to download', 'error', true);
      return null;
    } else {
      const lastFive = e.file_list.length > 5 ? e.file_list.slice(Math.max(e.file_list.length - 5, 0)) : e.file_list;

      context.modal.show({
        title: 'Download CSV',
        text: 'Please select a version of pixel to download.',
        form: {
          csv: {
            label: 'CSV',
            type: 'checkbox',
            options: lastFive.map((file) => format.timestampModal(file.createdAt)),
          },
        },
        buttonText: 'Download',
        url: `/api/pixel/${e.id}`,
        method: 'GET',
      }, (data) => {
        const filesToDownload = lastFive.map((file) => data.csv.value.includes(format.timestampModal(file.createdAt)) ? file.name : null).filter((f) => f !== null);
        context.notification.show('downloaded successfully', 'success', true);
        filesToDownload.forEach((d) => downloadCSV(e, d));
      });
    }
  };

  const getPixel = async () => {
    try {
      const list = await axios.get(
        `${baseUrlPixel}/api/pixel?page=${currentPage}&limit=10`,
      );
      const data = await list.data.data;
      dispatch(setPixel(addTypeCheck(data.docs)));
      dispatch(setPagValues({ total: data.totalDocs, limit: data.limit }));
      dispatch(setLoading(false));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(error.response);
      }
    }
  };

  // Search Results:
  const [searchResult, setSearchResult] = useState([]);
  const searchPixel = async () => {
    const data = await axios.post(`${baseUrlPixel}/api/pixel/getAll`);
    const pixelOneList = data.data.data?.filter((item) => !item.hasOwnProperty('identity_id'));
    setSearchResult(pixelOneList);
  };

  const sendTest = (data) => {
    if (data.data_webhook !== '') {
      axios.post(`${baseUrlPixel}/api/pixel/webhook`,
        {
          'webhook': `${data.data_webhook}`,
          'data': JSON.parse(webhookData),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Acept': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }
        },
      );
      context.notification.show('Test sent successfully', 'success', true);
    } else {
      context.notification.show('Webhook empty', 'error', true);
    }
  };

  const pixelValidationTest = (data) => {
    setIsValidatingPixel(true);
    context.notification.show('Checking Pixel Validation Status...', 'lowsuccess', true);
    setTimeout( async () => {
      if (data.data_webhook !== '') {
        let response = {};
        try {
          response = await axios.get(`${baseUrlPixel}/tag/validate/${data.id}`);
        } catch (error) {
          setIsValidatingPixel(false);
          context.notification.show(`Something went wrong while trying to check your Pixel's validation status.`, 'error', false);
          return;
        }
    
        const filteredResponse = response?.data ? response?.data : response.response?.data;
        const { totalScore, diagnostic } = filteredResponse ?? {};
    
        if (response.status !== 200 || response.error === 500) {
          setIsValidatingPixel(false);
          context.notification.show(`Something went wrong while trying to check your Pixel's validation status.`, 'error', false);
        } else {
          if (totalScore === 0 || (totalScore >= 0 && totalScore <= 20)) {
            setIsValidatingPixel(false);
            context.notification.show(diagnostic, 'error', true);
          } else if (totalScore > 20 && totalScore <= 60) {
            setIsValidatingPixel(false);
            context.notification.show(diagnostic, 'lowsuccess', true);
          } else if ((totalScore > 60 && totalScore < 100) || totalScore === 100) {
            setIsValidatingPixel(false);
            context.notification.show(diagnostic, 'success', true);
          } else {
            setIsValidatingPixel(false);
            context.notification.show(diagnostic, 'error', true);
          }
        }
      } else {
        setIsValidatingPixel(false);
        context.notification.show('Webhook empty', 'error', true);
      }
    }
    , 4000);
  };

  const getResolutions = async () => {
    const res = await axios.get(`${baseUrlMain}/api/access/quota/pixels?mode=result`);
    setResolutions(res.data.resultLimit);
  }

  useEffect(() => {
    getResolutions();
    dispatch(setCurrentPage(1));
    searchPixel();
  }, []);
  
  useEffect(() => {
    getPixel();
  }, [currentPage]);

/// FACEBOOK SYNC
  const FacebookPixel = async (data) => {
   let audienceHeader = {...data}; 
    audienceHeader["featuretype"] = "AudiencelabPixel";
    setFacebookAudienceData(audienceHeader);

    const pixelId = data.id;
    const isSyncedRequest = await axios.get(`${baseUrlMain}/api/facebook/hasSyncedPixel/${pixelId}?account_id=${accountId}`);
    const isSynced = isSyncedRequest.data.message;

    if (hasLoggedCondition) {

      if (isSynced) {
        context.notification.show('Your Pixel is already synced', 'success', true);

      } else {

        setShowSyncModal(true);
      }
      
    } else {
      context.newModal.show({
        title: "",
        children: <FacebookLoginModal/>,
    });
    }

  };

  function filterActions(actions) {
    let filtered;
  
    if (facebookIntegrationAccess) {
      filtered = actions;
    } else {
      filtered = actions.filter(action => action.icon !== "facebook");
    }
  
    return filtered;
  }

  const actionList = [
    { icon: 'edit', onClick: [editWebhook], tooltip: ['Edit Pixel'] },
    {
      icon: "facebook",
      color: "blue",
      tooltip: ["Sync Pixel with Facebook","Create Pixel first"],
      onClick: [FacebookPixel],
      // condition: [{ col: "status", values: ["COMPLETE"] }],
    },
    { icon: 'trash-2', onClick: [deletePixel], tooltip: ['Delete Pixel'] },
    { icon: 'arrow-right', onClick: [navigatePixel], tooltip: ['Enter Pixel'] },
    { icon: 'clipboard', onClick: [copyCode], tooltip: ['Copy Webhook'] },
    { icon: 'send', onClick: [sendTest], tooltip: ['Send Webhook'] },
    { icon: isValidatingPixel ? 'loader' : 'activity', onClick: !isValidatingPixel ? [pixelValidationTest]: null, tooltip: isValidatingPixel ? ['Pixel currently validating, please wait...'] : ['Check Pixel Validation Status'] },
    // {
    //   icon: 'download', onClick: [csvShowModal], tooltip: ['Download Pixel'],
    // }
  ]
  
  const filteredActions = filterActions(actionList)
  const filteredPixels = () => {
    const filteredPixels = pixels.map((pixel) => {
      if (pixel.trial?.isTrial) {
        const trialDays = `${pixel.trial.totalNumOfDays} remaining` || '';
        const trialResolutions = `${pixel.trial.totalResolutions} remaining` || '';
        return {...pixel, trialDays, trialResolutions};
      } else {
        return {...pixel, trialDays: '', trialResolutions: ''};
      }
    });    
    return filteredPixels;
  };
  
  useEffect(() => {
    filteredPixels();
  }, [pixels]);

  return (
    <Fragment>
      <Animate>
        {/* Title */}
        <div className='flex justify-between items-center'>
          <h2 className="m-6 text-3xl font-semibold">Manage Pixels</h2>
          <div className="float-left sm:float-right">
            <div className=" m-6 flex space-x-4">
              <button
                type="button"
                onClick={addPixel}
                className="inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
              <Icon image="plus-square" size={18} />
                  Add Pixel
              </button>
            </div>
          </div>
        </div>
        {showSyncModal && <SyncAudienceModal />}
        {isSyncSuccess && <FacebookSuccessModal />}
        <Message
          closable
          title='How to use action buttons:'
          action={sessionStorage.setItem('pixelMessage', true)}
          text={
            <>
              <span className='flex items-center space-x-2'>
                <Icon image='arrow-right' />
                <span>Enter pixel dashboard</span>
              </span>
              <span className='flex items-center space-x-2'>
                <Icon image='clipboard' />
                <span>Copy pixel code</span>
              </span>
              <span className='flex items-center space-x-2'>
                <Icon image='send' />
                <span>Send test webhook</span>
              </span>
              <span className='flex items-center space-x-2'>
                <Icon image='edit' />
                <span>Edit the webhook</span>
              </span>
              <span className='flex items-center space-x-2'>
                <Icon image='trash' />
                <span>Delete pixel</span>
              </span>
            </>
          }
          type='info'
        />
        <Quota
          type='pixels'
          quota={quota}
          setQuota={setQuota}
          unitsLabel='Pixel Units'
          resolutionsLabel='Pixel Resolutions'
        />
        <Card loading={loading}>
          <NewPaginate
            offset={currentPage - 1}
            limit={pagValues.limit}
            total={pagValues.total}
            next={() => dispatch(setCurrentPage(currentPage + 1))}
            prev={() => dispatch(setCurrentPage(currentPage - 1))}
            onChange={() => dispatch(setCurrentPage())}
          />
          <Table
            search={searchResult}
            className='restrict-width'
            data={filteredPixels()}
            loading={pixels.loading}
            show={['website_name', 'website_url', 'data_webhook', 'trialDays', 'trialResolutions']}
            actions={filteredActions}
          />
        </Card>

      </Animate>

    </Fragment>
  );
}
