/* eslint-disable no-constant-condition */
import { createSlice } from '@reduxjs/toolkit';
import uuid4 from 'uuid4';
import { checkType } from 'helpers/checkDataType';

const initialAudienceBuilderForm = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
  dateRangeLimit: {
    minDate: null,
    maxDate: null,
  },
  dateList: [],
  zipcodeList: [],
  zipcodeListText: [],
  cityList: [],
  notNull: [],
  nullOnly: [],
  stateList: [],
  businessList: [],
  job_title: [],
  seniority: [],
  department: [],
  company_name: [],
  company_domain: [],
  industry: [],
  sic: [],
  naics: [],
  employee_count: [],
  est_company_revenue: [],
  ageList: [],
  genderList: [],
  iabList: [],
  optionType: 'B2C',
  keywordList: [],
  personal: [],
  family: [],
  financial: [],
  house: [],
  auto: [],
  // ... add other default fields as needed
};

const initialState = {
  audienceList: [],
  audienceBuilderForm: initialAudienceBuilderForm,
  list: [],
  targets: '',
};

export const audienceBuilderSlice = createSlice({
  name: 'audienceBuilder',
  initialState,
  reducers: {
    addAudience: (state, action) => {
      state.audienceList.push(action.payload);
      state.audienceBuilderForm = { ...initialAudienceBuilderForm }; // Reset to initial state
    },
    deleteAudience: (state, action) => {
      state.audienceList = state.audienceList.filter((item) => item.id !== action.payload);
    },
    updateProgress: (state, action) => {
      const data = action.payload;
      const audienceProgress = state.audienceList.find((audience) => audience.id === data.id);
      if (audienceProgress) {
        audienceProgress.progress = data.progress;
        if (data.progress?.totalProgress && data.progress?.totalProgress === 100) {
          audienceProgress.status = 'COMPLETE';
          if (data.url) {
            if (!audienceProgress.fileDetailsList) {
              audienceProgress.fileDetailsList = [];
            }
            audienceProgress.fileDetailsList.push({
              name: data.url.split('/').pop(),
              url: data.url,
              _id: uuid4(),
              date: new Date().toISOString(),
            });
          }
        } else if (data.progress?.status === 'PROCESSING') {
          audienceProgress.status = `PROCESSING ${data.progress.totalProgress}%`;
        } else {
          audienceProgress.status = data.progress?.status;
        }
      }
    },
    setAudience: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.audienceList = [...action.payload];
      }
    },
    deleteInput: (state, action) => {
      state.audienceBuilderForm.keywordList = state.audienceBuilderForm.keywordList.filter(
        (item) => item.keyword !== action.payload.value
      );
    },
    updateForm: (state, action) => {
      const { fieldName, value } = action.payload;
      if (fieldName && value !== undefined) {
        if (fieldName === 'keywordList') {
          state.audienceBuilderForm.iabList = [];
        } else if (fieldName === 'iabList') {
          state.audienceBuilderForm.keywordList = [];
        }
        // Only update the field if it's part of the audienceBuilderForm
        if (state.audienceBuilderForm.hasOwnProperty(fieldName)) {
          state.audienceBuilderForm[fieldName] = value;
        } else {
          // If the field doesn't exist, you can choose to add it or ignore
          state.audienceBuilderForm[fieldName] = value;
        }
      }
    },
    removeForm: (state, action) => {
      state.audienceBuilderForm = {
        ...state.audienceBuilderForm,
        dateRange: {
          startDate: null,
          endDate: null,
        },
        // Optionally reset other fields if needed
      };
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setForm: (state, action) => {
      const reduxData = action.payload;
      if (!reduxData) return;
    
      const data = {
        // Ensure buildType is set
        buildType: reduxData.buildType || state.audienceBuilderForm.buildType || '',
    
        // Map and format fields as needed
        zipcodeList: reduxData.zipcodeList?.map((item) => ({
          value: item.value || item,
          label: item.label || item,
        })) || state.audienceBuilderForm.zipcodeList || [],
    
        zipcodeListText: reduxData.zipcodeListText?.map((item) => ({
          value: item.value || item,
          label: item.label || item,
        })) || state.audienceBuilderForm.zipcodeListText || [],
    
        cityList: reduxData.cityList?.map((item) => ({
          value: item.value || item,
          label: item.label || item,
        })) || state.audienceBuilderForm.cityList || [],
    
        notNull: reduxData.notNull || state.audienceBuilderForm.notNull || [],
        nullOnly: reduxData.nullOnly || state.audienceBuilderForm.nullOnly || [],
    
        stateList: reduxData.stateList?.map((item) => ({
          value: item.value || item,
          label: item.label || item,
        })) || state.audienceBuilderForm.stateList || [],
    
        businessList: reduxData.businessList || state.audienceBuilderForm.businessList || [],
        job_title: reduxData.businessList?.job_title || reduxData.job_title || state.audienceBuilderForm.job_title || [],
        seniority: reduxData.businessList?.seniority || reduxData.seniority || state.audienceBuilderForm.seniority || [],
        department: reduxData.businessList?.department || reduxData.department || state.audienceBuilderForm.department || [],
        company_name: reduxData.businessList?.company_name || reduxData.company_name || state.audienceBuilderForm.company_name || [],
        company_domain: reduxData.businessList?.company_domain || reduxData.company_domain || state.audienceBuilderForm.company_domain || [],
        industry: reduxData.businessList?.industry || reduxData.industry || state.audienceBuilderForm.industry || [],
        sic: reduxData.businessList?.sic || reduxData.sic || state.audienceBuilderForm.sic || [],
        naics: reduxData.businessList?.naics || reduxData.naics || state.audienceBuilderForm.naics || [],
        employee_count: reduxData.businessList?.employee_count || reduxData.employee_count || state.audienceBuilderForm.employee_count || [],
        est_company_revenue: reduxData.businessList?.est_company_revenue || reduxData.est_company_revenue || state.audienceBuilderForm.est_company_revenue || [],
    
        ageList: reduxData.ageList || state.audienceBuilderForm.ageList || [],
        genderList: reduxData.genderList || state.audienceBuilderForm.genderList || [],
        iabList: reduxData.iabList || state.audienceBuilderForm.iabList || [],
        optionType: reduxData.optionType || state.audienceBuilderForm.optionType || 'B2C',
        keywordList: reduxData.keywordList || state.audienceBuilderForm.keywordList || [],
    
        personal: reduxData.profile?.personal?.map((item) => ({
          id: uuid4(),
          name: item[0],
          field: item[0],
          operator: item[1],
          value_back: checkType(item[0], item[2]),
          value: item[2],
        })) || state.audienceBuilderForm.personal || [],
    
        family: reduxData.profile?.family?.map((item) => ({
          id: uuid4(),
          name: item[0],
          field: item[0],
          operator: item[1],
          value_back: checkType(item[0], item[2]),
          value: item[2],
        })) || state.audienceBuilderForm.family || [],
    
        financial: reduxData.profile?.financial?.map((item) => ({
          id: uuid4(),
          name: item[0],
          field: item[0],
          operator: item[1],
          value_back: checkType(item[0], item[2]),
          value: item[2],
        })) || state.audienceBuilderForm.financial || [],
    
        house: reduxData.profile?.house?.map((item) => ({
          id: uuid4(),
          name: item[0],
          field: item[0],
          operator: item[1],
          value_back: checkType(item[0], item[2]),
          value: item[2],
        })) || state.audienceBuilderForm.house || [],
    
        auto: reduxData.profile?.auto?.map((item) => ({
          id: uuid4(),
          name: item[0],
          field: item[0],
          operator: item[1],
          value_back: checkType(item[0], item[2]),
          value: item[2],
        })) || state.audienceBuilderForm.auto || [],
    
        // Date fields with defaults
        dateRange: reduxData.dateRange || state.audienceBuilderForm.dateRange || { startDate: null, endDate: null },
        dateRangeLimit: reduxData.dateRangeLimit || state.audienceBuilderForm.dateRangeLimit || { minDate: null, maxDate: null },
      };
    
      // Merge the new data with the existing form to preserve any fields not being updated
      state.audienceBuilderForm = {
        ...state.audienceBuilderForm,
        ...data,
      };
    },
    setZipcodes: (state, action) => {
      if (state.audienceBuilderForm.zipcodeList) {
        const existingBackValues = new Set(state.audienceBuilderForm.zipcodeList.map(item => item.back));
    
        const uniqueZipcodesToAdd = action.payload.filter(item => !existingBackValues.has(item.back));
    
        state.audienceBuilderForm.zipcodeList = [...state.audienceBuilderForm.zipcodeList, ...uniqueZipcodesToAdd];
      }
    },
    updateList: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  addAudience,
  deleteAudience,
  setAudience,
  deleteInput,
  updateForm,
  updateList,
  removeForm,
  setForm,
  setZipcodes,
  setTargets,
  updateProgress,
} = audienceBuilderSlice.actions;

export default audienceBuilderSlice.reducer;