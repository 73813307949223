// B2bBuilder.jsx

import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import { Card, Icon, ViewContext, NewPaginate, Table } from "components/lib";
import {
  updateForm,
  clearForm,
  setList,
  setForm,
  setCurrentBuilderPage,
  setPagValues,
} from "redux/features/businessBuilder/businessBuilderSlice";
import Style from "./b2b.tailwind";
import department from "./options/department";
import employeeCount from "./options/employee_count";
import industry from "./options/industry";
import revenue from "./options/revenue";
import seniority from "./options/seniority";
import personalState from "./options/personal_state";
import personalCity from "./options/personal_city";
import sic from "./options/sic";
import naics from "./options/naics";
import { BusinessText } from "./inputs/b2b_text_input";
import { BusinessSelect } from "./inputs/b2b_select_input";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { checkLocation } from "helpers/checklocation";
import axios from "axios";
import uuid4 from "uuid4";

export const B2bBuilder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const baseUrl = checkLocation();
  const context = useContext(ViewContext);

  // State Variables
  const [currentTab, setCurrentTab] = useState("Contacts");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [b2bItem, setb2bItem] = useState({});
  const [formId, setFormId] = useState("");

  // Redux State Selectors
  const currentBuilderPage = useSelector(
    (state) => state.businessBuilder.currentBuilderPage
  );
  const pagValues = useSelector((state) => state.businessBuilder.pagValues);
  const formState = useSelector(
    (state) => state.businessBuilder.businessBuilderForm
  );
  const b2bList = useSelector((state) => state.businessBuilder.businessList);

  // useRef Hooks for Managing Timers
  const pollingIntervalRef = useRef(null);

  // HANDLERS

  // Switch between Contacts and Companies tabs
  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  // Clear all form fields
  const handleClearForm = async (e) => {
    dispatch(clearForm());
  };

  // Handle changes in select inputs and update Redux form state
  const handleBusinessSelectChange = (label, value) => {
    dispatch(
      updateForm({
        label: label
          ?.toLowerCase()
          .replace(/[^\w\s]/gi, "")
          .replace(/\s/g, "_"),
        value,
        form: currentTab.toLowerCase(),
      })
    );
  };

  // Render Select Inputs
  const inputSelect = (label, json) => {
    const inputs =
      formState[currentTab.toLowerCase()]?.[
        label.toLowerCase().replace(/\s/g, "_")
      ] || [];
    return (
      <span>
        <label className="relative block text-sm mb-2 uppercase">{label}</label>
        <BusinessSelect
          options={json.map((field) => ({
            value: field.label,
            label: field.label,
          }))}
          onChange={handleBusinessSelectChange}
          isMulti
          isSearchable
          lazyLoad={false}
          name={label}
          defaultValue={inputs?.map((item) => ({ value: item, label: item }))}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: "none",
              neutral10: "#ced4da",
              neutral80: "#18181B",
              neutral15: "#18181B",
              dangerLight: "#ced4da",
              danger: "#52525B",
            },
          })}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              minHeight: "42px",
            }),
          }}
          closeMenuOnSelect={false}
        />
      </span>
    );
  };

  // Render Text Inputs
  const inputText = (label, number = false) => {
    const fieldKey = label.toLowerCase().replace(/\s/g, "_");
    const inputs =
      formState[currentTab.toLowerCase()]?.[fieldKey] || [];
    return (
      <span>
        <label className="relative block text-sm mb-2 uppercase">{label}</label>
        <BusinessText
          label={label}
          form={currentTab}
          inputs={inputs}
          number={number}
        />
      </span>
    );
  };

  // Function to check if form is empty
  const isFormEmpty = () => {
    const contacts = formState.contacts || {};
    const companies = formState.companies || {};

    const hasContactsValues = Object.values(contacts).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === 'string') {
        return value.trim() !== '';
      } else {
        return false;
      }
    });

    const hasCompaniesValues = Object.values(companies).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === 'string') {
        return value.trim() !== '';
      } else {
        return false;
      }
    });

    return !(hasContactsValues || hasCompaniesValues); // Returns true if both are empty
  };

  // Search Button
  const searchButton = () => {
    const formEmpty = isFormEmpty();
    return (
      <button
        disabled={loading || generating || formEmpty}
        className={`${
          !loading && !generating && !formEmpty
            ? "cursor-pointer bg-blue-600 hover:bg-blue-700 hover:border-blue-700"
            : "bg-gray-500 pointer-events-none"
        } font-sans text-sm px-7 py-2.5 float-right mr-[2%] last:mr-0 text-white relative block text-center font-semibold rounded-md ease-in-out duration-500`}
        onClick={sendQuery}
      >
        {loading ? "Searching..." : "Search"}
      </button>
    );
  };

  // Clear All button remains unchanged
  const clearButton = () => {
    return (
      <button
        className="cursor-pointer font-sans hover:text-red text-sm float-right mr-[2%] last:mr-0 hover:text-white border text-zinc-500 hover:border-white hover:bg-red-400 bg-transparent relative block text-center font-semibold rounded-md px-7 py-2.5 ease-in-out duration-500"
        onClick={handleClearForm}
      >
        Clear All
      </button>
    );
  };

  /**
   * Generate Data Button
   * Always enabled. On click, checks if visualData exists before proceeding.
   */
  const generateB2bSearchDataButton = () => {
    const handleClick = async (e) => {
      // Function to determine if visualData exists
      const hasVisualData = () => {
        if (b2bItem?.visualData?.length > 0) {
          console.log("Top-level visualData found:", b2bItem.visualData);
          return true;
        }
        // Check the latest formStateList entry for visualData
        const lastFormState = b2bItem?.formStateList?.[b2bItem.formStateList.length - 1];
        if (lastFormState?.visualData?.length > 0) {
          console.log("FormStateList visualData found:", lastFormState.visualData);
          return true;
        }
        return false;
      };

      if (hasVisualData()) {
        await generateB2bSearchData(e);
      } else {
        console.warn("No visualData available for generating data.");
        context.notification.show(
          "Generate Data can only be performed after a successful search preview.",
          "warning",
          true
        );
      }
    };

    return (
      <button
        onClick={handleClick}
        className={`bg-blue-600 cursor-pointer hover:bg-blue-700 ${
          generating ? "opacity-50 cursor-not-allowed" : ""
        } ${Style.default} text-white flex space-x-1 items-center `}
        disabled={generating} // Disable only when generating
      >
        <Icon image="refresh-ccw" color="white" size={16} />
        <span>{generating ? "Processing..." : "Generate Data"}</span>
      </button>
    );
  };

  /**
   * Download Button remains the same
   */
  const downloadButton = () => {
    return (
      <button
        onClick={downloadB2bSearch}
        className={`${
          b2bItem?.fileDetailsList?.length > 0
            ? "bg-green-300 cursor-pointer"
            : "bg-gray-300 pointer-events-none" // Changed to gray when not clickable
        } ${
          Style.default
        } text-slate-500 border rounded-md flex space-x-1 items-center `}
      >
        <Icon image="download" color="dark" size={16} />
        <span>Download</span>
      </button>
    );
  };

  // PAGINATION & RESULTS

  /**
   * Handles frontend pagination by slicing the data based on the current page and limit.
   */
  const paginationFrontend = (limit, currentBuilderPage, item) => {
    if (item) {
      const data =
        item?.visualData ||
        item?.formStateList[item?.formStateList?.length - 1]?.visualData;
      const startIndex = (currentBuilderPage - 1) * limit;
      const endIndex = startIndex + limit;

      const paginatedData = data?.slice(startIndex, endIndex);
      setResults(paginatedData);
    } else {
      dispatch(setCurrentBuilderPage(1));
    }
  };

  /**
   * Displays search results based on file availability
   */
  const showResults = (loading, results, b2bItem) => {
    if (loading) {
      // Show loading spinner
      return (
        <div className="h-screen w-screen z-50 flex justify-center items-center">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </div>
      );
    }
  
    if (results && results.length > 0) {
      return (
        <Fragment>
          <span className="relative block text-sm mb-2 uppercase">
            Count{" "}
            {
              b2bItem?.formStateList[b2bItem?.formStateList?.length - 1]
                ?.totalCount
            }
          </span>
          <Table
            data={results}
            show={["FIRST_NAME", "LAST_NAME", "COMPANY_NAME", "LINKEDIN_URL"]}
          />
        </Fragment>
      );
    } else if (b2bItem?.status === "NO_DATA") {
      return <div>No data found. Please modify your search.</div>;
    } else if (
      b2bItem?.status === "PROCESSING" ||
      b2bItem?.status === "IN QUEUE" ||
      b2bItem?.status === "GENERATE_DATA"
    ) {
      // Show loading spinner
      return (
        <div className="h-screen w-screen z-50 flex justify-center items-center">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </div>
      );
    } else {
      return <div>Perform a search to see results.</div>;
    }
  };
  

  // API CALLS

  /**
   * Updates the form inputs on the backend.
   */
  const updateInputs = async (e, id) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${baseUrl}/api/b2bSearch/${params.id}/updateFormState/${id}`,
        { contacts: formState.contacts, companies: formState.companies }
      );
      console.log("Form inputs updated successfully.");
    } catch (error) {
      console.error("Error updating form inputs:", error);
      context.notification.show(
        "An error occurred while updating form inputs.",
        "error",
        true
      );
    }
  };

  /**
   * Handles the Search operation.
   * Allows multiple searches and does not charge credits.
   */
  const sendQuery = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      context.notification.show(
        "Please fill at least one field before searching.",
        "warning",
        true
      );
      return;
    }

    setLoading(true);
    try {
      await updateInputs(e, formId);
      await axios.get(
        `${baseUrl}/api/b2bSearch/${params.id}/generateSearch/${formId}?download=false`
      );
      context.notification.show("Search started processing", "success", true);

      let retries = 0;
      const maxRetries = 200;

      const interval = setInterval(async () => {
        retries += 1;
        const result = await getResults();
        if (
          result &&
          result.status !== "CREATED" &&
          result.status !== "IN QUEUE" &&
          result.status !== "PROCESSING"
        ) {
          setb2bItem(result);
          clearInterval(interval);
          setLoading(false);
          context.notification.show("Search completed successfully.", "success", true);
        } else if (retries >= maxRetries) {
          clearInterval(interval);
          setLoading(false);
          context.notification.show(
            "Search is taking longer than expected. Please try again later.",
            "error",
            true
          );
        }
      }, 3000);
    } catch (error) {
      setLoading(false);
      console.error(error);
      context.notification.show(
        "An error occurred while initiating the search.",
        "error",
        true
      );
    }
  };

  /**
   * Handles downloading the search results.
   */
  const downloadB2bSearch = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/`);
      const item = res?.data?.data?.docs[0];
      const status = item.status;
      const file = item.fileDetailsList[item.fileDetailsList?.length - 1]?.name;
      if (file && status !== "NO_DATA") {
        const CSV = "https://audiencelab-builder.s3.amazonaws.com/" + file;
        window.open(CSV, "_blank").focus();
      } else {
        context.notification.show("No data found to download.", "error", true);
      }
    } catch (error) {
      console.error(error);
      context.notification.show(
        "An error occurred while downloading the data.",
        "error",
        true
      );
    }
  };

  /**
   * Handles generating B2B search data.
   * Charges credits, and manages the generating state.
   */
  const generateB2bSearchData = async (e) => {
    try {
      e.preventDefault();
      setGenerating(true); // Start generating state

      await axios.get(
        `${baseUrl}/api/b2bSearch/${params.id}/generateSearch/${formId}?download=true`
      );
      context.notification.show("Started generating file", "success", true);

      let retries = 0;
      const maxRetries = 200;

      // Start polling for results every 3 seconds
      pollingIntervalRef.current = setInterval(async () => {
        try {
          const result = await getResults();
          retries += 1;
          if (
            result &&
            result.status !== "CREATED" &&
            result.status !== "IN QUEUE" &&
            result.status !== "PROCESSING"
          ) {
            setb2bItem(result);
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
            setGenerating(false); // Stop generating state
            context.notification.show("Data generation completed.", "success", true);
          } else if (retries >= maxRetries) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
            setGenerating(false);
            context.notification.show(
              "Data generation is taking longer than expected. Please try again later.",
              "error",
              true
            );
          }
        } catch (resultError) {
          console.error("An error occurred while getting results:", resultError);
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
          setGenerating(false); // Ensure generating state is stopped on error
          context.notification.show(
            "An error occurred during data generation.",
            "error",
            true
          );
        }
      }, 3000);
    } catch (error) {
      console.error("An error occurred:", error);
      setGenerating(false);
      context.notification.show(
        "An error occurred while initiating data generation.",
        "error",
        true
      );
    }
  };

  /**
   * Fetches the latest search results from the backend.
   */
  const getResults = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/`);
      const item = res?.data?.data?.docs[0];
      
      // Normalize visualData to be at the top level by merging all visualData from formStateList
      const mergedVisualData = [
        ...(item?.visualData || []),
        ...(item?.formStateList?.flatMap(fs => fs.visualData) || [])
      ];
      
      item.visualData = mergedVisualData;

      paginationFrontend(25, currentBuilderPage, item);

      if (mergedVisualData.length > 0) {
        dispatch(
          setPagValues({ total: mergedVisualData.length, limit: 25 })
        );
      } else {
        dispatch(setPagValues({}));
      }
      setb2bItem(item);
      return item;
    } catch (error) {
      console.error("An error occurred while fetching results:", error);
      return null;
    }
  };

  /**
   * Fetches the list of B2B searches and initializes the component state.
   */
  const getB2bList = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch?limit=100`);
      const docs = res.data.data.docs;
      dispatch(setList(docs));
      const item = docs?.find((item) => item.id === params.id);
      if (item) {
        setb2bItem(item);
        if (item?.formStateList && item.formStateList?.length) {
          setFormId(item?.formStateList[item?.formStateList?.length - 1]?.id);
          dispatch(
            setForm({
              id: item?.formStateList[item?.formStateList?.length - 1]?.id,
              contacts:
                item?.formStateList[item?.formStateList?.length - 1]?.formState
                  ?.contacts || {},
              companies:
                item?.formStateList[item?.formStateList?.length - 1]?.formState
                  ?.companies || {},
            })
          );
        }
      }
      if (item?.status === "COMPLETE") {
        dispatch(
          setPagValues({
            total:
              item?.formStateList[item?.formStateList?.length - 1]?.visualData
                ?.length ||
              item?.visualData?.length ||
              1,
            limit: 25,
          })
        );
      } else {
        dispatch(setPagValues({}));
      }
    } catch (error) {
      console.error("An error occurred while fetching the B2B list:", error);
      context.notification.show(
        "An error occurred while fetching the B2B list.",
        "error",
        true
      );
    }
  };

  // INITIAL SETUP

  /**
   * Set the current builder page to 1 on component mount.
   */
  useEffect(() => {
    dispatch(setCurrentBuilderPage(1));
  }, [dispatch]);

  /**
   * Fetch data when b2bList, currentBuilderPage, or params.id changes.
   */
  useEffect(() => {
    let interval;

    const fetchData = async () => {
      if (!b2bList.length) {
        await getB2bList();
      }
      if (b2bList.length) {
        const item = b2bList?.find((item) => item.id === params.id);
        const id = item?.formStateList[item?.formStateList?.length - 1]?.id;
        setb2bItem(item);
        paginationFrontend(25, currentBuilderPage, item);
        if (id) {
          setFormId(id);
        } else {
          setFormId(uuid4());
        }

        if (item?.status === "PROCESSING" || item?.status === "IN QUEUE") {
          interval = setInterval(async () => {
            const result = await getResults();
            if (
              result &&
              result?.status !== "CREATED" &&
              result?.status !== "PROCESSING" &&
              result?.status !== "IN QUEUE"
            ) {
              setb2bItem(result);
              clearInterval(interval);
              setLoading(false);
              context.notification.show("Search completed successfully.", "success", true);
            }
          }, 3000);
        } else {
          setLoading(false);
          getResults();
        }
      }
    };

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, [b2bList, currentBuilderPage, params.id]);

  /**
   * Update form inputs when formId or formState changes.
   */
  useEffect(() => {
    if (formId && formState) {
      const mockEvent = { preventDefault: () => {} };
      updateInputs(mockEvent, formId);
    }
  }, [formId, formState]);

  /**
   * Cleanup Effect to clear timers when the component unmounts.
   */
  useEffect(() => {
    return () => {
      // Clear the polling interval if it exists
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  return (
    <Fragment>
      {/* Header */}
      <section className="overflow-auto">
        {/* Buttons - Generate Data and Download */}
        <div className="m-6 flex justify-between items-center">
          <h2 className="text-3xl font-semibold">Business</h2>
          <div className="float-right flex space-x-5">
            {generateB2bSearchDataButton()}
            {downloadButton()}
          </div>
        </div>
        {/* Tabs */}
        <div className="m-6 float-left text-lg w-1/3">
          <div className="flex space-x-2">
            <button
              onClick={() => handleTabSwitch("Contacts")}
              className={`${Style.default} ${
                currentTab === "Contacts" ? Style.selected : Style.not_selected
              } w-full border rounded-md`}
            >
              Contacts
            </button>
            <button
              onClick={() => handleTabSwitch("Companies")}
              className={`${Style.default} ${
                currentTab === "Companies" ? Style.selected : Style.not_selected
              } w-full border rounded-md`}
            >
              Companies
            </button>
          </div>
        </div>
      </section>

      {/* Forms and Results */}
      <section className="h-full">
        <div className="flex flex-col lg:flex-row lg:w-full lg:space-x-4">
          {/* Contacts Form */}
          {currentTab === "Contacts" && (
            <Card className="flex-auto w-full lg:w-1/3 h-full">
              {b2bList.length > 0 && (
                <form>
                  {inputText("First Name")}
                  {inputText("Last Name")}
                  {inputText("Email")}
                  {inputText("Email Domain")}
                  {inputText("Business Email")}
                  {inputText("Business Email Domain")}
                  {inputText("Job Title")}
                  {inputSelect("Seniority", seniority)}
                  {inputSelect("Department", department)}
                  {inputSelect("Personal State", personalState)}
                  {inputSelect("Personal City", personalCity)}
                  {inputText("Personal Zip", true)}
                  {inputText("Linkedin URL")}
                  <div className="flex justify-between items-center">
                    {clearButton()}
                    {searchButton()}
                  </div>
                </form>
              )}
            </Card>
          )}
          {/* Companies Form */}
          {currentTab === "Companies" && (
            <Card className="flex-auto w-full lg:w-1/3 h-auto">
              {b2bList.length > 0 && (
                <form>
                  {inputSelect("Company Industry", industry)}
                  {inputSelect("Company SIC", sic)}
                  {inputSelect("Company NAICS", naics)}
                  {inputSelect("Company Revenue", revenue)}
                  {inputSelect("Employee Count", employeeCount)}
                  {inputText("Company Name")}
                  {inputText("Company Domain")}
                  <div className="flex justify-between items-center">
                    {clearButton()}
                    {searchButton()}
                  </div>
                </form>
              )}
            </Card>
          )}
          {/* Search Results */}
          <Card className="flex-auto w-full lg:w-3/5">
            <NewPaginate
              offset={currentBuilderPage - 1}
              limit={pagValues.limit}
              total={pagValues.total}
              currentPage={currentBuilderPage}
              next={() =>
                dispatch(setCurrentBuilderPage(currentBuilderPage + 1))
              }
              prev={() =>
                dispatch(setCurrentBuilderPage(currentBuilderPage - 1))
              }
              onChange={() => dispatch(setCurrentBuilderPage())}
            />
            {showResults(loading, results, b2bItem)}
          </Card>
        </div>
      </section>
    </Fragment>
  );
};