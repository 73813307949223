import {createSlice} from '@reduxjs/toolkit';
import uuid4 from 'uuid4';


export const businessBuilderSlice = createSlice({
  name: 'businessBuilder',
  initialState: {
    businessList: [],
    businessBuilderForm: {
      id: uuid4(),
      contacts: {
        first_name: [],
        last_name: [],
        email: [],
        email_domain: [],
        business_email: [],
        business_email_domain: [],
        job_title: [],
        seniority: [],
        department: [],
        personal_state: [],
        personal_city: [],
        personal_zip: [],
        linkedin_url: [],
      },
      companies: {
        company_industry: [],
        company_sic: [],
        company_naics: [],
        company_revenue: [],
        employee_count: [],
        company_name: [],
        company_domain: [],
      },
    },
    currentIndexPage: 1,
    currentBuilderPage: 1,
    pagValues: {},
  },
  reducers: {
    setList: (state, action) => {
      state.businessList = action.payload;
    },
    setForm: (state, action) => {
      state.businessBuilderForm = action.payload;
    },
    updateForm: (state, action) => {
      const {label, value, form} = action.payload;
      const existingValues = state.businessBuilderForm[form][label];
      const originalValues = value.filter((val) => !existingValues?.includes(val));
      const newValues = originalValues.map((item) => item.value);
      state.businessBuilderForm[form][label] = newValues;
    },
    clearForm: (state) => {
      state.businessBuilderForm = {
        id: uuid4(),
        contacts: {
          first_name: [],
          last_name: [],
          email: [],
          email_domain: [],
          business_email: [],
          business_email_domain: [],
          job_title: [],
          seniority: [],
          department: [],
          personal_state: [],
          personal_city: [],
          personal_zip: [],
          linkedin_url: [],
        },
        companies: {
          company_industry: [],
          company_sic: [],
          company_naics: [],
          company_revenue: [],
          employee_count: [],
          company_name: [],
          company_domain: [],
        },
      };
    },
    setCurrentIndexPage: (state, action) => {
      state.currentIndexPage = action.payload;
    },
    setCurrentBuilderPage: (state, action) => {
      state.currentBuilderPage = action.payload;
    },
    setPagValues: (state, action) => {
      state.pagValues = action.payload;
    },
  },
});


export const {setList, setForm, updateForm, clearForm, setCurrentIndexPage, setCurrentBuilderPage, setPagValues} = businessBuilderSlice.actions;
export default businessBuilderSlice.reducer;
